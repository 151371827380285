import React from "react";
import { ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import ValueWithLabel from "../../../../../components/Shared/ValueWithLabel";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
const parseConfiguration = (configuration) => {
  try {
    return JSON.parse(JSON.parse(configuration));
  } catch (Error) {
    return JSON.parse(configuration);
  }
};
export const InstanceItem = ({ configuration }) => {
  const parsedConfig = parseConfiguration(configuration);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Launched" }, `${dayjs(parsedConfig.launchTime).format("llll")} (${dayjs(parsedConfig.launchTime).fromNow()})`), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Architecture" }, parsedConfig.architecture), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "AMI" }, parsedConfig.imageId), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Instance type" }, parsedConfig.instanceType), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Private DNS" }, parsedConfig.privateDnsName), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Private IP" }, `${parsedConfig.privateIpAddress}`)), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Public DNS" }, parsedConfig.publicDnsName), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Public IP" }, `${parsedConfig.publicIpAddress}`), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Monitoring" }, parsedConfig.monitoring.state), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Platform" }, parsedConfig.platform), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "CPU cores" }, parsedConfig.cpuOptions.coreCount), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "CPU threads per core" }, parsedConfig.cpuOptions.threadsPerCore)));
};
export default InstanceItem;
