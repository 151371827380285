import React from "react";
import { Box, Button, Icon, SpaceBetween } from "@cloudscape-design/components";
function ErrorFallback({ error, resetErrorBoundary }) {
  return /* @__PURE__ */ React.createElement(Box, { padding: "xxxl", textAlign: "center" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "m" }, /* @__PURE__ */ React.createElement(
    Icon,
    {
      variant: "error",
      name: "status-negative",
      size: "large"
    }
  ), /* @__PURE__ */ React.createElement(Box, { variant: "h1" }, "Something went wrong!"), /* @__PURE__ */ React.createElement(Box, { color: "text-body-secondary" }, error.message), /* @__PURE__ */ React.createElement(Button, { onClick: resetErrorBoundary }, "Reload")));
}
export default ErrorFallback;
