import React, { useMemo } from "react";
import { Header, Container, Input } from "@cloudscape-design/components";
import PropTypes from "prop-types";
import AttributeCreator from "../../../Utils/Forms/AttributeCreator";
const Control = React.memo(({ value, index, placeholder, setItems, prop }) => {
  return /* @__PURE__ */ React.createElement(
    Input,
    {
      value,
      placeholder,
      onChange: ({ detail }) => {
        setItems((items) => {
          const updatedItems = [...items];
          updatedItems[index] = {
            ...updatedItems[index],
            [prop]: detail.value
          };
          return updatedItems;
        });
      }
    }
  );
});
Control.displayName = "Control";
function controlCell(resourceArn, itemIndex, setARNs) {
  return /* @__PURE__ */ React.createElement(
    Control,
    {
      prop: "resourceArn",
      value: resourceArn,
      index: itemIndex,
      placeholder: "Enter an Resource Id or ARN",
      setItems: setARNs
    }
  );
}
const CostARNQuerySelector = ({ arns, setARNs }) => {
  const definition = useMemo(
    () => [
      {
        label: "Resource",
        control: ({ resourceArn = "" }, itemIndex) => controlCell(resourceArn, itemIndex, setARNs)
      }
    ],
    [setARNs]
  );
  return /* @__PURE__ */ React.createElement(
    Container,
    {
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          variant: "h2",
          description: "Specify the AWS resources to query"
        },
        "AWS Resources"
      )
    },
    /* @__PURE__ */ React.createElement(
      AttributeCreator,
      {
        item: "Resource ID or ARN",
        items: arns,
        label: "Amazon Resource Name (ARN) or Resource Id",
        placeholder: "Enter a resource Id or ARN",
        itemAdded: () => setARNs([...arns, {}]),
        itemRemoved: (itemIndex) => {
          const tmpItems = [...arns];
          tmpItems.splice(itemIndex, 1);
          setARNs(tmpItems);
        },
        setItems: setARNs,
        definition
      }
    )
  );
};
CostARNQuerySelector.propTypes = {
  arns: PropTypes.array.isRequired,
  setARNs: PropTypes.func.isRequired
};
export default CostARNQuerySelector;
