import React from "react";
import {
  Box,
  TextFilter,
  Header,
  SpaceBetween,
  Table,
  Pagination
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { fetchImage } from "../../../Utils/ImageSelector";
import * as R from "ramda";
function imageCell(item) {
  return /* @__PURE__ */ React.createElement("img", { src: fetchImage(item.type), style: { width: "25px" } });
}
const ViewExplorerResourceTypesTable = ({ selectedView }) => {
  const { items, filterProps, collectionProps, paginationProps } = useCollection(R.pathOr([], ["resourceTypes"], selectedView), {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No resource types"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No resource types to display"
      )),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No resource types matched."
      ))
    },
    pagination: { pageSize: 5 },
    sorting: {},
    selection: { keepSelection: true, trackBy: (i) => i.id }
  });
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l", direction: "vertical" }, /* @__PURE__ */ React.createElement(
    Table,
    {
      ...collectionProps,
      trackBy: "type",
      ariaLabels: {
        tableLabel: "Resources types"
      },
      columnDefinitions: [
        {
          id: "icon",
          cell: (e) => imageCell(e)
        },
        {
          id: "type",
          header: "Type",
          cell: (e) => e.type
        }
      ],
      items,
      loadingText: "Loading Resource types",
      visibleColumns: ["icon", "type"],
      filter: /* @__PURE__ */ React.createElement(
        TextFilter,
        {
          ...filterProps,
          filteringPlaceholder: "Find a resource type"
        }
      ),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps }),
      header: /* @__PURE__ */ React.createElement(Header, null, "Resource types")
    }
  ));
};
export default ViewExplorerResourceTypesTable;
