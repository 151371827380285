import React from "react";
import { Container, LineChart, Box } from "@cloudscape-design/components";
import PropTypes from "prop-types";
import * as R from "ramda";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
const descending = function(a, b) {
  return b - a;
};
const ascending = function(a, b) {
  return a - b;
};
const mapIndexed = R.addIndex(R.map);
const CostBreakdownChart = ({ items }) => {
  const processGroups = (groupBy) => {
    const groups = groupBy(items);
    return mapIndexed((e, i) => {
      return {
        id: i,
        title: `${R.last(R.split(":", e))} (${R.head(groups[e]).product_servicename})`,
        type: "line",
        valueFormatter: (t) => t,
        data: R.map((e2) => {
          return {
            x: dayjs(e2.line_item_usage_start_date).format("ll"),
            y: e2.cost
          };
        }, groups[`${e}`])
      };
    }, Object.keys(groups));
  };
  const byARN = R.groupBy((e) => e.line_item_resource_id);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(
    LineChart,
    {
      hideFilter: true,
      series: R.isEmpty(processGroups(byARN)) ? [] : processGroups(byARN),
      xDomain: R.map(
        (x) => x.format("ll"),
        R.sort(
          ascending,
          R.uniq(
            R.map(
              (e) => dayjs(e.line_item_usage_start_date),
              items
            )
          )
        )
      ),
      yDomain: [
        -0,
        R.head(
          R.sort(descending, R.uniq(R.map((e) => e.cost, items)))
        )
      ],
      i18nStrings: {
        chartAriaRoleDescription: "line chart",
        xTickFormatter: (t) => t.split(",").join("\n"),
        yTickFormatter: (t) => t
      },
      ariaLabel: "Multiple data series line chart",
      errorText: "Error loading data.",
      height: 300,
      loadingText: "Loading chart",
      recoveryText: "Retry",
      statusType: "finished",
      xScaleType: "categorical",
      xTitle: "Date",
      yTitle: "Estimated cost ($)",
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No data available"), /* @__PURE__ */ React.createElement(Box, { variant: "p", color: "inherit" }, "Try selecting a resource in the table above and choosing ", /* @__PURE__ */ React.createElement("strong", null, "Update Graph"))),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No matching data"), /* @__PURE__ */ React.createElement(Box, { variant: "p", color: "inherit" }, "There is no matching data to display"))
    }
  ));
};
CostBreakdownChart.propTypes = {
  items: PropTypes.array.isRequired
};
export default CostBreakdownChart;
