import React from "react";
import PropTypes from "prop-types";
import { Form, SpaceBetween } from "@cloudscape-design/components";
import CostDatePicker from "../../Diagrams/Draw/Utils/CostDatePicker";
const CostForm = ({ setDateInterval }) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "l" }, /* @__PURE__ */ React.createElement(
    CostDatePicker,
    {
      disabled: false,
      onIntervalChange: setDateInterval
    }
  ))));
};
CostForm.propTypes = {
  setDateInterval: PropTypes.func.isRequired
};
export default CostForm;
