import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../GraphQL/queries";
import * as mutations from "../GraphQL/mutations";
import * as R from "ramda";
export const getResources = (params) => {
  return API.graphql(graphqlOperation(queries.getResources, params));
};
export const getResourcesPaginated = ({ accounts, resourceTypes, pageSize = 500 }) => {
  async function getResourcesRec(pagination, resources = []) {
    const { end } = pagination;
    const page = await getResources({ accounts, resourceTypes, pagination }).then(R.pathOr([], ["data", "getResources"]));
    if (R.isEmpty(page))
      return resources;
    return getResourcesRec({ start: end, end: end + pageSize }, [...page, ...resources]);
  }
  return getResourcesRec({ start: 0, end: pageSize });
};
export const getRelationships = (params) => {
  return API.graphql(graphqlOperation(queries.getRelationships, params));
};
export const getRelationshipsPaginated = ({ pageSize = 1500, ...params }) => {
  async function getRelationshipsRec(pagination, resources = []) {
    const { end } = pagination;
    const page = await getRelationships({ pagination, ...params }).then(R.pathOr([], ["data", "getRelationships"]));
    if (R.isEmpty(page))
      return resources;
    return getRelationshipsRec({ start: end, end: end + pageSize }, [...page, ...resources]);
  }
  return getRelationshipsRec({ start: 0, end: pageSize });
};
export const getResourceGraph = (params) => {
  return API.graphql(graphqlOperation(queries.getResourceGraph, params));
};
export const getResourceGraphPaginated = ({ ids, pageSize = 500 }) => {
  async function getResourceGraphRec(pagination, resourceGraph = { nodes: [], edges: [] }) {
    const { end } = pagination;
    const { nodes, edges } = await getResourceGraph({ ids, pagination }).then(
      R.pathOr({ nodes: [], edges: [] }, ["data", "getResourceGraph"])
    );
    if (R.isEmpty(nodes) && R.isEmpty(edges))
      return resourceGraph;
    return getResourceGraphRec(
      { start: end, end: end + pageSize },
      {
        nodes: [...resourceGraph.nodes, ...nodes],
        edges: [...resourceGraph.edges, ...edges]
      }
    );
  }
  return getResourceGraphRec({ start: 0, end: pageSize });
};
export const getAccountResourceGraph = (params) => {
  return API.graphql(graphqlOperation(queries.getAccountResourceGraph, params));
};
export const getAccountResourceGraphPaginated = ({ pageSize = 500, ...params }) => {
  async function getAccountResourceGraphRec(pagination, resourceGraph = { nodes: [], edges: [] }) {
    const { end } = pagination;
    const { nodes, edges } = await getAccountResourceGraph({ ...params, pagination }).then(R.pathOr({ nodes: [], edges: [] }, ["data", "getAccountResourceGraph"]));
    if (R.isEmpty(nodes) && R.isEmpty(edges))
      return resourceGraph;
    return getAccountResourceGraphRec({ start: end, end: end + pageSize }, {
      nodes: [...resourceGraph.nodes, ...nodes],
      edges: [...resourceGraph.edges, ...edges]
    });
  }
  return getAccountResourceGraphRec({ start: 0, end: pageSize });
};
export const getResourcesMetadata = (params) => {
  return API.graphql(graphqlOperation(queries.getResourcesMetadata, params));
};
export const getResourcesAccountMetadata = (params) => {
  return API.graphql(
    graphqlOperation(queries.getResourcesAccountMetadata, params)
  );
};
export const getResourcesRegionMetadata = (params) => {
  return API.graphql(
    graphqlOperation(queries.getResourcesRegionMetadata, params)
  );
};
export const searchResources = (params) => {
  return API.graphql(graphqlOperation(queries.searchResources, params));
};
export const exportToDrawIo = (params) => {
  return API.graphql(graphqlOperation(queries.exportToDrawIo, params));
};
export const createApplication = (params) => {
  return API.graphql(graphqlOperation(mutations.createApplication, params));
};
export function handleResponse(response) {
  if (!response || response.error) {
    throw new InvalidRequestException(response.body.errors);
  } else
    return response;
}
class InvalidRequestException extends Error {
  constructor(errors = [], ...args) {
    super(errors.map((e) => e.message).join(", "), ...args);
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
