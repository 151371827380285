import React from "react";
import {
  Box,
  TextFilter,
  Header,
  SpaceBetween,
  Table,
  Pagination
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { regionMap } from "../../../Utils/Dictionaries/RegionMap";
import * as R from "ramda";
const ViewExplorerRegionsTable = ({ selectedView }) => {
  const regions = selectedView.accounts?.reduce(
    (acc, next) => R.uniq(acc.concat(next.regions ?? [])),
    []
  ) ?? [];
  const { items, filterProps, collectionProps, paginationProps } = useCollection(regions, {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Regions"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Regions to display"
      )),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Regions matched."
      ))
    },
    pagination: { pageSize: 5 },
    sorting: {}
  });
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l", direction: "vertical" }, /* @__PURE__ */ React.createElement(
    Table,
    {
      ...collectionProps,
      trackBy: "id",
      ariaLabels: {
        itemSelectionLabel: (e, t) => `select ${t.accountId}`,
        selectionGroupLabel: "Item selection"
      },
      columnDefinitions: [
        {
          id: "awsId",
          header: "Id",
          cell: (e) => e.name
        },
        {
          id: "name",
          header: "Name",
          cell: (e) => R.find(R.propEq("id", e.name), regionMap).name
        }
      ],
      items,
      loadingText: "Loading Regions",
      visibleColumns: ["awsId", "name"],
      filter: /* @__PURE__ */ React.createElement(
        TextFilter,
        {
          ...filterProps,
          filteringPlaceholder: "Find a Region"
        }
      ),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps }),
      header: /* @__PURE__ */ React.createElement(Header, null, "Regions")
    }
  ));
};
export default ViewExplorerRegionsTable;
