import React from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import Breadcrumbs from "../../../../Utils/Breadcrumbs";
import { DRAW } from "../../../../routes";
import { useDiagramSettingsState } from "../../../Contexts/DiagramSettingsContext";
import DiagramTable from "../../Management/DiagramTable";
const DrawDiagramPage = () => {
  const [, dispatch] = useDiagramSettingsState();
  React.useEffect(() => {
    dispatch({
      type: "clearCanvas"
    });
  }, [dispatch]);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(Breadcrumbs, { items: [{ text: "Diagrams", href: DRAW }] }), /* @__PURE__ */ React.createElement(DiagramTable, null));
};
export default DrawDiagramPage;
