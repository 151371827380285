import React from "react";
import {
  FormField,
  SpaceBetween,
  Button,
  Form,
  Select,
  Autosuggest,
  Multiselect
} from "@cloudscape-design/components";
import { useHistory } from "react-router-dom";
import { OPEN_DIAGRAM } from "../../../../../routes";
import { useDiagramSettingsState } from "../../../../Contexts/DiagramSettingsContext";
import { diagramsPrefix, useListObjects, usePutObject } from "../../../../Hooks/useS3Objects";
import * as R from "ramda";
import validFilename from "valid-filename";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { DEFAULT_COSTS_INTERVAL } from "../../../../../config/constants";
import { useGetAccountResourceGraph, useGetResourceGraph } from "../../../../Hooks/useGetResourceGraph";
import { useGetResources } from "../../../../Hooks/useResources";
import { useResourceState } from "../../../../Contexts/ResourceContext";
import { useResourcesMetadata } from "../../../../Hooks/useResourcesMetadata";
import { fetchImage } from "../../../../../Utils/ImageSelector";
import { useGetRelationships } from "../../../../Hooks/useRelationships";
import { wrapRequest } from "../../../../../Utils/API/HandlerUtils";
import { processResourcesError } from "../../../../../Utils/ErrorHandlingUtils";
import { getRelationshipsPaginated, handleResponse } from "../../../../../API/Handlers/ResourceGraphQLHandler";
import { processElements } from "../../../../../API/APIProcessors";
dayjs.extend(relativeTime);
const mapIndexed = R.addIndex(R.map);
const defaultResourceTypes = [
  "AWS::ApiGateway::RestApi",
  "AWS::CloudFront::Distribution",
  "AWS::Cognito::UserPool",
  "AWS::EC2::EIP",
  "AWS::EC2::Instance",
  "AWS::S3::Bucket",
  "AWS::DynamoDB::Table",
  "AWS::EC2::NetworkInterface",
  "AWS::EC2::NatGateway",
  "AWS::EC2::VPCEndpoint",
  "AWS::EC2::InternetGateway",
  "AWS::EC2::Subnet",
  "AWS::EC2::Host",
  "AWS::EC2::Instance",
  "AWS::EC2::EgressOnlyInternetGateway",
  "AWS::EC2::TransitGateway",
  "AWS::EC2::VPCPeeringConnection",
  "AWS::EC2::ClientVpnEndpoint",
  "AWS::EC2::VPNConnection",
  "AWS::EC2::VPNGateway",
  "AWS::EC2::Volume",
  "AWS::EC2::VPC",
  "AWS::ECS::Cluster",
  "AWS::ECS::Service",
  "AWS::ECS::Task",
  "AWS::Events::EventBus",
  "AWS::OpenSearch::Domain",
  "AWS::RDS::DBInstance",
  "AWS::Route53::HostedZone",
  "AWS::SNS::Topic",
  "AWS::AppSync::GraphQLApi",
  "AWS::Lambda::Function",
  "AWS::SecretsManager::Secret",
  "AWS::WAF::WebACL",
  "AWS::WAFRegional::WebACL",
  "AWS::WAF::RuleGroup",
  "AWS::WAFRegional::RuleGroup",
  "AWS::WAFv2::WebACL",
  "AWS::WAFv2::RuleGroup",
  "AWS::ElasticLoadBalancingV2::LoadBalancer",
  "AWS::ElasticLoadBalancing::LoadBalancer",
  "AWS::ElasticLoadBalancingV2::LoadBalancer",
  "AWS::ApiGateway::RestApi",
  "AWS::ApiGatewayV2::Api",
  "AWS::EKS::Cluster",
  "AWS::EFS::FileSystem",
  "AWS::EFS::AccessPoint",
  "AWS::Elasticsearch::Domain",
  "AWS::Kinesis::Stream",
  "AWS::KinesisFirehose::DeliveryStream",
  "AWS::Redshift::Cluster",
  "AWS::Redshift::EndpointAccess",
  "AWS::SageMaker::Domain",
  "AWS::Amplify::App",
  "AWS::AppMesh::Mesh",
  "AWS::ElasticBeanstalk::Application",
  "AWS::NetworkFirewall::Firewall",
  "AWS::StepFunctions::Activity",
  "AWS::StepFunctions::StateMachine"
];
const CreateDiagramNameForm = ({ direction, accounts }) => {
  const [{ canvas }] = useDiagramSettingsState();
  const [, dispatch] = useResourceState();
  const [name, setName] = React.useState("");
  const [resourceIds, setResourceIds] = React.useState([]);
  const [account, setAccount] = React.useState({
    label: "Select an account",
    value: null
  });
  const [visibility, setVisibility] = React.useState({
    label: "Private",
    value: "private"
  });
  const { data = [], refetch } = useListObjects(diagramsPrefix, visibility.value);
  const { putAsync } = usePutObject(diagramsPrefix);
  const { data: resourcesMetadata, status } = useResourcesMetadata();
  const resourceTypes = (resourcesMetadata?.resourceTypes ?? []).map((x) => x.type);
  const resourceTypesSet = new Set(resourceTypes);
  const [selectedResourceTypes, setSelectedResourceTypes] = React.useState(null);
  const defaultSelectedValues = defaultResourceTypes.filter((x) => resourceTypesSet.has(x)).map((type) => ({
    label: type,
    value: type,
    iconUrl: fetchImage(type)
  }));
  const { refetch: loadAccount, isLoading, isError } = useGetAccountResourceGraph(
    account.accountId,
    (selectedResourceTypes ?? defaultSelectedValues).map((i) => i.value)
  );
  const history = useHistory();
  const diagrams = mapIndexed((e, index) => {
    return {
      id: index,
      name: e.key.split("/")[e.key.split("/").length - 1],
      modified: e.lastModified
    };
  })(data);
  const handleCreate = () => {
    return loadAccount().then(async ({ data: accountData }) => {
      const nodes = accountData.filter((x) => x.group === "nodes");
      const nodeSet = new Set(nodes.map((n) => n.data.id));
      const edges = accountData.filter((x) => {
        return x.group === "edges" && nodeSet.has(x.data.source) && nodeSet.has(x.data.target);
      });
      const accountNode = nodes.find((n) => n.data.type === "account");
      if (accountNode != null) {
        const accountInfo = accounts.find((a) => a.accountId === account.accountId);
        accountNode.data.label = `${accountNode.data.label} - ${accountInfo.name} - ${accountInfo.bindleName ?? ""}`;
      }
      return putAsync({
        key: name,
        level: visibility.value,
        type: "application/json",
        content: JSON.stringify({
          ...canvas?.json().elements ?? { nodes, edges },
          settings: {
            costInterval: DEFAULT_COSTS_INTERVAL,
            accounts: [],
            regions: [],
            resourceTypes: [],
            hideSelected: true,
            hideEdges: false
          }
        })
      }).then(() => {
        dispatch({
          type: "updateGraphResources",
          graphResources: [...nodes, edges]
        });
      }).then(() => history.push(
        OPEN_DIAGRAM.replace(":name", name).replace(
          ":visibility",
          visibility.value
        )
      )).catch(console.error);
    });
  };
  const diagramExists = () => R.filter((e) => R.equals(e.name, name), diagrams).length > 0;
  const isValidForm = () => !R.isEmpty(name) && validFilename(name) && !(account.value == null);
  return /* @__PURE__ */ React.createElement(
    Form,
    {
      actions: /* @__PURE__ */ React.createElement(
        Button,
        {
          iconAlign: "right",
          onClick: handleCreate,
          disabled: !isValidForm() || isLoading,
          variant: diagramExists() ? "normal" : "primary"
        },
        diagramExists() ? "Overwrite" : "Create"
      )
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { direction, size: "l" }, /* @__PURE__ */ React.createElement(FormField, { label: "Visibility" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        selectedOption: visibility,
        onChange: ({ detail }) => setVisibility(detail.selectedOption),
        options: [
          { label: "Private", value: "private" },
          { label: "Public", value: "public" }
        ],
        selectedAriaLabel: "Selected"
      }
    )), /* @__PURE__ */ React.createElement(FormField, { label: "Account" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        selectedOption: account,
        onChange: ({ detail }) => setAccount(detail.selectedOption),
        options: accounts.map((x) => {
          return {
            accountId: x.accountId,
            value: x.accountId,
            label: x.name,
            description: x.accountId
          };
        }),
        filteringType: "auto"
      }
    )), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Resource Types",
        description: "Select which resource types to display"
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          placeholder: "Select resource types",
          onChange: (e) => {
            setSelectedResourceTypes(e.detail.selectedOptions);
          },
          options: [
            {
              label: "Select All",
              options: resourceTypes.map((type) => ({
                label: type,
                value: type,
                iconUrl: fetchImage(type)
              }))
            }
          ],
          filteringType: "auto",
          selectedOptions: selectedResourceTypes ?? defaultSelectedValues,
          selectedAriaLabel: "Selected",
          expandToViewport: true,
          tokenLimit: 6,
          statusType: status
        }
      )
    ), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Name",
        errorText: diagramExists() ? "A diagram with this name already exists" : null
      },
      /* @__PURE__ */ React.createElement(
        Autosuggest,
        {
          onChange: ({ detail }) => setName(detail.value),
          value: name,
          options: R.map((e) => {
            return { value: e.name };
          }, diagrams),
          enteredTextLabel: (value) => `Use: "${value}"`,
          ariaLabel: "Autosuggest example with suggestions",
          placeholder: "Enter a name",
          empty: "No matches found",
          onFocus: refetch
        }
      )
    ))
  );
};
export default CreateDiagramNameForm;
