import React, { useCallback, useEffect } from "react";
import { Container, Header, SpaceBetween } from "@cloudscape-design/components";
import Breadcrumbs from "../../../../../Utils/Breadcrumbs";
import { CREATE_DIAGRAM, DRAW } from "../../../../../routes";
import { useParams } from "react-router-dom";
import PureCytoscape from "../../Canvas/PureCytoscape";
import CreateAccountDiagramNameForm from "./CreateAccountDiagramNameForm";
import { useResourceState } from "../../../../Contexts/ResourceContext";
import { useDiagramSettingsState } from "../../../../Contexts/DiagramSettingsContext";
import { addResources } from "../../Canvas/Commands/CanvasCommands";
import { getStandardLayout } from "../../Canvas/Layout/StandardGraphLayout";
import { useAccounts } from "../../../../Hooks/useAccounts";
const CreateDiagramPage = () => {
  const [{ canvas }, dispatchCanvas] = useDiagramSettingsState();
  const [{ graphResources }] = useResourceState();
  const [rendered, setRendered] = React.useState(false);
  const { name, visibility } = useParams();
  const { data: accounts = [] } = useAccounts();
  const updateCanvas = useCallback((newCanvas) => {
    dispatchCanvas({
      type: "setCanvas",
      canvas: newCanvas
    });
  }, [dispatchCanvas]);
  const updateResources = useCallback(() => {
    dispatchCanvas({
      type: "setResources",
      resources: canvas.nodes()
    });
  }, [canvas, dispatchCanvas]);
  useEffect(() => {
    if (canvas && rendered) {
      addResources(
        canvas,
        updateCanvas,
        updateResources,
        graphResources,
        getStandardLayout
      );
    }
  }, [canvas, graphResources, rendered, updateCanvas, updateResources]);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(
    Breadcrumbs,
    {
      items: [
        { text: "Diagrams", href: DRAW },
        { text: "Create", href: CREATE_DIAGRAM }
      ]
    }
  ), /* @__PURE__ */ React.createElement(Container, { header: /* @__PURE__ */ React.createElement(Header, { variant: "h2" }, "Create Diagram") }, /* @__PURE__ */ React.createElement(CreateAccountDiagramNameForm, { accounts, direction: "vertical" })));
};
export default CreateDiagramPage;
