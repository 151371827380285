import React from "react";
import {
  Authenticator,
  Heading,
  Image,
  ThemeProvider,
  Button
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import "@cloudscape-design/global-styles/index.css";
import "@aws-amplify/ui-react/styles.css";
import Main from "./Main";
import { Box, SpaceBetween } from "@cloudscape-design/components";
import * as awsui from "@cloudscape-design/design-tokens";
const components = {
  Header() {
    return /* @__PURE__ */ React.createElement(
      Box,
      {
        margin: { vertical: "m", horizontal: "xl" },
        textAlign: "center"
      },
      /* @__PURE__ */ React.createElement(SpaceBetween, { size: "xl" }, /* @__PURE__ */ React.createElement(
        Image,
        {
          alt: "Workload Discovery on AWS icon",
          src: "/icons/AWS-Zoom_light-bg.svg",
          objectFit: "initial",
          objectPosition: "50% 50%",
          backgroundColor: "initial",
          height: "120px",
          width: "120px",
          opacity: "100%"
        }
      ), /* @__PURE__ */ React.createElement(Heading, { level: 3 }, "Workload Discovery on AWS"))
    );
  },
  Footer() {
    return window.amplify.Auth?.federatedIdpResource != null ? /* @__PURE__ */ React.createElement(SpaceBetween, { size: "xs" }, /* @__PURE__ */ React.createElement(Box, { margin: { vertical: "m" }, textAlign: "center" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        align: "center",
        variation: "primary",
        onClick: () => Auth.federatedSignIn({
          customProvider: window.amplify.Auth.federatedIdpResource
        })
      },
      " ",
      "Sign in via Federated Identity Provider"
    ))) : null;
  }
};
export const App = () => {
  const theme = {
    name: "theme",
    tokens: {
      fonts: {
        default: {
          variable: { value: awsui.fontFamilyBase },
          static: { value: awsui.fontFamilyBase }
        }
      },
      colors: {
        brand: {
          primary: {
            10: { value: awsui.colorBackgroundButtonPrimaryDisabled },
            80: { value: awsui.colorBackgroundButtonPrimaryDefault },
            90: { value: awsui.colorBackgroundButtonPrimaryHover },
            100: { value: awsui.colorBackgroundButtonPrimaryActive }
          }
        }
      }
    }
  };
  return /* @__PURE__ */ React.createElement(ThemeProvider, { theme }, /* @__PURE__ */ React.createElement(Authenticator, { components, hideSignUp: true }, /* @__PURE__ */ React.createElement(Main, null)));
};
export default App;
