import React from "react";
import {
  Button,
  Form,
  SpaceBetween,
  Modal,
  Box,
  Checkbox,
  Alert,
  Link
} from "@cloudscape-design/components";
import RegionProvider from "./RegionProvider";
import Breadcrumbs from "../../../Utils/Breadcrumbs";
import { ACCOUNTS, IMPORT } from "../../../routes";
import * as R from "ramda";
import { useAccounts, useAddAccounts } from "../../Hooks/useAccounts";
import { useHistory } from "react-router-dom";
import TemplateProvider from "./TemplateProvider";
const ImportContent = () => {
  const history = useHistory();
  const { addAsync: addAccounts, isLoading } = useAddAccounts();
  const { data: accounts = [] } = useAccounts();
  const [regions, setRegions] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [hasConfirmed, setHasConfirmed] = React.useState({
    global: false,
    regional: false
  });
  const importedRegions = R.reduce(
    (acc, e) => R.concat(
      acc,
      R.chain((region) => {
        return {
          accountId: e.accountId,
          accountName: e.name,
          region: region.name,
          isIamRoleDeployed: e.isIamRoleDeployed,
          isManagementAccount: e.isManagementAccount,
          lastCrawled: e.lastCrawled
        };
      }, e.regions)
    ),
    [],
    accounts
  );
  const byAccount = R.groupBy((e) => e.accountId);
  const importAccounts = async () => {
    const mergedRegions = R.mergeWith(
      R.concat,
      byAccount(regions),
      byAccount(importedRegions)
    );
    const accounts2 = Object.values(mergedRegions).map((accInfo) => {
      return accInfo.reduce(
        (acc, item) => {
          const {
            accountId,
            accountName,
            region,
            isIamRoleDeployed,
            isManagementAccount,
            lastCrawled
          } = item;
          return {
            accountId,
            name: accountName ?? acc.name,
            isManagementAccount: isManagementAccount ?? acc.isManagementAccount,
            isIamRoleDeployed: isIamRoleDeployed ?? acc.isIamRoleDeployed,
            lastCrawled: lastCrawled ?? acc.lastCrawled,
            regions: [...acc.regions, { name: region }]
          };
        },
        { regions: [] }
      );
    });
    return addAccounts(accounts2);
  };
  const handleSubmit = () => {
    setSubmitting(true);
    importAccounts().then(() => history.push(ACCOUNTS)).finally(setSubmitting(false));
  };
  return /* @__PURE__ */ React.createElement(
    Form,
    {
      actions: /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          disabled: isLoading,
          variant: "link",
          onClick: () => history.push(ACCOUNTS)
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          disabled: regions.length === 0,
          loading: isLoading || submitting,
          onClick: () => setShowConfirm(true),
          variant: "primary"
        },
        "Import"
      ))
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(
      Breadcrumbs,
      {
        items: [
          { text: "Accounts", href: ACCOUNTS },
          { text: "Import", href: IMPORT }
        ]
      }
    ), /* @__PURE__ */ React.createElement(RegionProvider, { regions, setRegions }), /* @__PURE__ */ React.createElement(
      Modal,
      {
        onDismiss: () => setShowConfirm(false),
        visible: showConfirm,
        size: "large",
        closeAriaLabel: "Close modal",
        footer: /* @__PURE__ */ React.createElement(Box, { float: "right" }, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
          Button,
          {
            onClick: () => setShowConfirm(false),
            variant: "link"
          },
          "Cancel"
        ), /* @__PURE__ */ React.createElement(
          Button,
          {
            disabled: Object.values(hasConfirmed).some(
              (i) => !i
            ),
            onClick: handleSubmit,
            variant: "primary"
          },
          "Import"
        ))),
        header: "Import Accounts and Regions"
      },
      /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s" }, /* @__PURE__ */ React.createElement(TemplateProvider, null), /* @__PURE__ */ React.createElement(
        Alert,
        {
          header: "Confirm Template Deployment",
          type: "info"
        },
        /* @__PURE__ */ React.createElement(SpaceBetween, { size: "xs" }, /* @__PURE__ */ React.createElement(Box, null, "Please confirm the global and regional templates are deployed in the required accounts and regions. For deploying the required CloudFormation templates to multiple accounts and regions, we recommend using", " ", /* @__PURE__ */ React.createElement(
          Link,
          {
            external: true,
            href: "https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-concepts.html"
          },
          "AWS CloudFormation StackSets"
        )), /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            onChange: ({ detail }) => setHasConfirmed((e) => ({
              ...e,
              global: detail.checked
            })),
            checked: hasConfirmed.global
          },
          /* @__PURE__ */ React.createElement("strong", null, "The global resources template is deployed in each of the accounts being imported")
        ), /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            onChange: ({ detail }) => setHasConfirmed((e) => ({
              ...e,
              regional: detail.checked
            })),
            checked: hasConfirmed.regional
          },
          /* @__PURE__ */ React.createElement("strong", null, "The regional resources template is deployed in every region being imported for each of the listed accounts")
        ))
      ))
    ))
  );
};
export default ImportContent;
