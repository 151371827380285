import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Form,
  FormField,
  Modal,
  Multiselect,
  RadioGroup,
  SpaceBetween,
  Toggle
} from "@cloudscape-design/components";
import CostDatePicker from "./CostDatePicker";
import * as R from "ramda";
import { useResourcesMetadata } from "../../../Hooks/useResourcesMetadata";
import { fetchImage } from "../../../../Utils/ImageSelector";
const DiagramSettings = ({ onSettingsChange, initialSettings }) => {
  const [settings, setSettings] = useState(initialSettings);
  const [lastSubmittedSettings, setLastSubmittedSettings] = useState(settings);
  const [hasDestructiveChanges, setHasDestructiveChanges] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { data = { accounts: [], resourceTypes: [] }, status } = useResourcesMetadata();
  const uniqRegions = R.compose(
    R.uniq,
    R.chain(
      (i) => i.regions?.map((j) => ({ label: j.name, value: j.name })) ?? []
    )
  );
  const options = {
    accounts: data.accounts.map((i) => ({
      label: i.accountId,
      value: i.accountId
    })),
    regions: uniqRegions(data.accounts),
    resourceTypes: data.resourceTypes.map((i) => ({
      label: i.type,
      value: i.type,
      iconUrl: fetchImage(i.type)
    }))
  };
  const confirmSubmit = () => {
    setHasDestructiveChanges(false);
    setLastSubmittedSettings(settings);
    onSettingsChange && onSettingsChange(settings);
    setHasUnsavedChanges(false);
  };
  const handleSubmit = () => {
    const isDestructiveKey = (val, key) => !["costInterval", "hideEdges", "dataFlowEdgesOnly"].includes(key);
    const filterNonDestructiveKeys = R.pickBy(isDestructiveKey);
    if (!R.equals(
      filterNonDestructiveKeys(settings),
      filterNonDestructiveKeys(lastSubmittedSettings)
    )) {
      setHasDestructiveChanges(true);
    } else {
      confirmSubmit();
    }
  };
  useEffect(() => {
    setHasUnsavedChanges(!R.equals(settings, initialSettings));
  }, [settings, initialSettings]);
  const handleChange = (prop, value) => {
    setSettings({
      ...settings,
      [prop]: value
    });
  };
  const filterTypeOptions = [
    { label: "Hide Selected", value: true },
    { label: "Only Show Selected", value: false }
  ];
  return /* @__PURE__ */ React.createElement("form", { onSubmit: (e) => e.preventDefault() }, /* @__PURE__ */ React.createElement(
    Modal,
    {
      onDismiss: () => setHasDestructiveChanges(false),
      visible: hasDestructiveChanges,
      closeAriaLabel: "Close modal",
      footer: /* @__PURE__ */ React.createElement(Box, { float: "right" }, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => setHasDestructiveChanges(false),
          variant: "link"
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(Button, { onClick: confirmSubmit, variant: "primary" }, "Apply"))),
      header: "Apply Settings"
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s" }, /* @__PURE__ */ React.createElement(Box, null, "Modifying the filters will cause some elements to be removed from your diagram. Are you sure you wish to apply these settings?"), /* @__PURE__ */ React.createElement(Alert, { header: "Persisting Settings" }, "You must also save the diagram after applying new settings in order to persist these changes"))
  ), /* @__PURE__ */ React.createElement(
    Form,
    {
      actions: /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: handleSubmit,
          disabled: !hasUnsavedChanges,
          variant: "primary"
        },
        "Apply"
      )
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s" }, /* @__PURE__ */ React.createElement(
      CostDatePicker,
      {
        initialInterval: settings.costInterval,
        normalizeInterval: false,
        onIntervalChange: (val) => handleChange("costInterval", val)
      }
    ), /* @__PURE__ */ React.createElement(FormField, { label: "Filter type" }, /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        onChange: ({ detail }) => handleChange("hideSelected", detail.value),
        value: settings.hideSelected,
        items: filterTypeOptions
      }
    )), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Accounts",
        description: "Select which accounts to display"
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          placeholder: "Select accounts",
          onChange: (e) => handleChange(
            "accounts",
            e.detail.selectedOptions.map((i) => i.value)
          ),
          options: options.accounts,
          filteringType: "auto",
          selectedOptions: options.accounts.filter(
            (i) => settings.accounts.includes(i.value)
          ),
          selectedAriaLabel: "Selected",
          expandToViewport: true,
          statusType: status
        }
      )
    ), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Regions",
        description: "Select which regions to display"
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          placeholder: "Select regions",
          onChange: (e) => handleChange(
            "regions",
            e.detail.selectedOptions.map((i) => i.value)
          ),
          options: options.regions,
          filteringType: "auto",
          selectedOptions: options.regions.filter(
            (i) => settings.regions.includes(i.value)
          ),
          selectedAriaLabel: "Selected",
          expandToViewport: true,
          statusType: status
        }
      )
    ), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Resource Types",
        description: "Select which resource types to display"
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          placeholder: "Select resource types",
          onChange: (e) => handleChange(
            "resourceTypes",
            e.detail.selectedOptions.map((i) => i.value)
          ),
          options: options.resourceTypes,
          filteringType: "auto",
          selectedOptions: options.resourceTypes.filter(
            (i) => settings.resourceTypes.includes(i.value)
          ),
          selectedAriaLabel: "Selected",
          expandToViewport: true,
          statusType: status
        }
      )
    ), /* @__PURE__ */ React.createElement(
      Toggle,
      {
        onChange: ({ detail }) => handleChange("hideEdges", detail.checked),
        checked: settings.hideEdges
      },
      "Hide Edges"
    ), !settings.hideEdges && /* @__PURE__ */ React.createElement(
      Toggle,
      {
        onChange: ({ detail }) => handleChange(
          "dataFlowEdgesOnly",
          detail.checked
        ),
        checked: settings.dataFlowEdgesOnly ?? false
      },
      "Data Flow Edges Only"
    ))
  ));
};
export default DiagramSettings;
