import React from "react";
import { ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import ValueWithLabel from "../../../../../components/Shared/ValueWithLabel";
const parseConfiguration = (configuration) => {
  try {
    return JSON.parse(JSON.parse(configuration));
  } catch (Error) {
    return JSON.parse(configuration);
  }
};
export const DatabaseInstanceItem = ({ configuration }) => {
  const parsedConfig = parseConfiguration(configuration);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Engine" }, `${parsedConfig.engine} - ${parsedConfig.engineVersion}`), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Instance class" }, parsedConfig.dBInstanceClass), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Database name" }, parsedConfig.dBName), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Backup window" }, parsedConfig.preferredBackupWindow), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Maintainance window" }, `${parsedConfig.preferredMaintenanceWindow}`)), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Certificate" }, parsedConfig.cACertificateIdentifier), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Storage encrypted" }, `${parsedConfig.storageEncrypted}`), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Endpoint" }, parsedConfig.endpoint ? `${parsedConfig.endpoint.address}:${parsedConfig.endpoint.port}` : ""), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Latest restorable time" }, parsedConfig.latestRestorableTime)));
};
export default DatabaseInstanceItem;
