import React, { useState } from "react";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination,
  SpaceBetween,
  Button,
  Modal,
  Link
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { CREATE_DIAGRAM, OPEN_DIAGRAM } from "../../../routes";
import { useHistory } from "react-router-dom";
import * as R from "ramda";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  diagramsPrefix,
  privateLevel,
  publicLevel,
  useListObjects,
  useRemoveObject
} from "../../Hooks/useS3Objects";
import useLink from "../../Hooks/useLink";
dayjs.extend(relativeTime);
const DiagramTable = () => {
  const [selectedDiagrams, setSelectedDiagrams] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const history = useHistory();
  const {
    data: privateDiagrams = [],
    isLoading: loadingPrivate,
    refetch: refetchPrivate
  } = useListObjects(diagramsPrefix, privateLevel);
  const {
    data: publicDiagrams = [],
    isLoading: loadingPublic,
    refetch: refetchPublic
  } = useListObjects(diagramsPrefix, publicLevel);
  const { removeAsync } = useRemoveObject(diagramsPrefix);
  const { handleFollow } = useLink();
  const loading = loadingPrivate || loadingPublic;
  const refreshDiagrams = () => Promise.all([refetchPrivate(), refetchPublic()]);
  function link(item) {
    return /* @__PURE__ */ React.createElement(
      Link,
      {
        href: OPEN_DIAGRAM.replace(":name", item.label).replace(
          ":visibility",
          item.visibility
        ),
        onFollow: handleFollow
      },
      item.label
    );
  }
  const columns = [
    {
      id: "label",
      header: "Name",
      cell: (e) => link(e),
      width: 350,
      minWidth: 350
    },
    {
      id: "visibility",
      header: "Visibility",
      cell: (e) => e.visibility.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }),
      width: 150,
      minWidth: 150
    },
    {
      id: "lastModified",
      header: "Last modified",
      cell: (e) => R.isNil(e.lastModified) ? "No data" : dayjs(e.lastModified).fromNow(),
      width: 150,
      minWidth: 150
    }
  ];
  const mapper = (visibility) => (e) => {
    return {
      label: R.split("/", e.key)[1],
      value: e.key,
      visibility,
      tags: [visibility],
      lastModified: e.lastModified
    };
  };
  const diagrams = R.concat(
    R.map(mapper(privateLevel), privateDiagrams),
    R.map(mapper(publicLevel), publicDiagrams)
  );
  const { items, filterProps, collectionProps, paginationProps } = useCollection(diagrams, {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Architecture diagrams"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Architecture diagrams to display."
      )),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Architecture diagrams matched."
      ))
    },
    pagination: { pageSize: 10 },
    sorting: { sortingColumn: "name" }
  });
  const handleDelete = () => {
    removeAsync({
      key: R.head(selectedDiagrams).label,
      level: R.head(selectedDiagrams).visibility
    }).then(() => {
      setSelectedDiagrams([]);
      setShowDeleteConfirm(false);
    });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Modal,
    {
      onDismiss: () => setShowDeleteConfirm(false),
      visible: showDeleteConfirm,
      closeAriaLabel: "Close modal",
      footer: /* @__PURE__ */ React.createElement(Box, { float: "right" }, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => setShowDeleteConfirm(false),
          variant: "link"
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(Button, { onClick: handleDelete, variant: "primary" }, "Delete"))),
      header: "Delete Diagram"
    },
    "Permanently delete",
    " ",
    /* @__PURE__ */ React.createElement("strong", null, selectedDiagrams.map((i) => i.label).join(", ")),
    "? This cannot be undone."
  ), /* @__PURE__ */ React.createElement(
    Table,
    {
      ...collectionProps,
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          actions: /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              disabled: R.isEmpty(selectedDiagrams),
              onClick: () => setShowDeleteConfirm(true),
              variant: "normal"
            },
            "Delete"
          ), /* @__PURE__ */ React.createElement(
            Button,
            {
              disabled: R.isEmpty(selectedDiagrams),
              onClick: () => history.push(
                OPEN_DIAGRAM.replace(
                  ":name",
                  R.head(selectedDiagrams).label
                ).replace(
                  ":visibility",
                  R.head(selectedDiagrams).visibility
                )
              )
            },
            "Open"
          ), /* @__PURE__ */ React.createElement(
            Button,
            {
              variant: "primary",
              onClick: () => history.push(CREATE_DIAGRAM)
            },
            "Create"
          ), /* @__PURE__ */ React.createElement(
            Button,
            {
              onClick: () => refreshDiagrams(),
              iconName: "refresh"
            }
          )),
          variant: "h2"
        },
        "Diagrams"
      ),
      trackBy: "label",
      resizableColumns: true,
      stickyHeader: true,
      columnDefinitions: columns,
      items,
      loading,
      selectedItems: selectedDiagrams,
      selectionType: "single",
      onSelectionChange: (evt) => {
        setSelectedDiagrams(evt.detail.selectedItems);
      },
      filter: /* @__PURE__ */ React.createElement(
        TextFilter,
        {
          ...filterProps,
          filteringPlaceholder: "Find a diagram"
        }
      ),
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Architecture diagrams"), /* @__PURE__ */ React.createElement(
        Box,
        {
          padding: { bottom: "s" },
          variant: "p",
          color: "inherit"
        },
        "No Architecture diagrams to display."
      )),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps })
    }
  ));
};
DiagramTable.propTypes = {};
export default DiagramTable;
