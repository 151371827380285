import React, { useCallback, useEffect } from "react";
import {
  Container,
  ExpandableSection,
  Header,
  SpaceBetween
} from "@cloudscape-design/components";
import Breadcrumbs from "../../../../../Utils/Breadcrumbs";
import { DRAW, OPEN_DIAGRAM } from "../../../../../routes";
import { useParams } from "react-router-dom";
import ResourceSearch from "../../Utils/ResourceSearch";
import DiagramControlPanel from "../DiagramControlsPanel";
import PureCytoscape from "../../Canvas/PureCytoscape";
import { useDiagramSettingsState } from "../../../../Contexts/DiagramSettingsContext";
import {
  addResources,
  removeResource
} from "../../Canvas/Commands/CanvasCommands";
import { getStandardLayout } from "../../Canvas/Layout/StandardGraphLayout";
import DiagramSettings from "../../Utils/DiagramSettings";
import { useObject } from "../../../../Hooks/useS3Objects";
import * as R from "ramda";
import { DEFAULT_COSTS_INTERVAL } from "../../../../../config/constants";
const DIAGRAMS = "diagrams/";
const OpenDiagramPage = () => {
  const [{ canvas, resources }, dispatchCanvas] = useDiagramSettingsState();
  const [settings, setSettings] = React.useState(null);
  const { name, visibility } = useParams();
  const { data } = useObject(name, DIAGRAMS, visibility);
  const updateCanvas = useCallback(
    (newCanvas) => {
      dispatchCanvas({
        type: "setCanvas",
        canvas: newCanvas
      });
    },
    [dispatchCanvas]
  );
  const updateResources = useCallback(() => {
    dispatchCanvas({
      type: "setResources",
      resources: canvas.nodes()
    });
  }, [canvas, dispatchCanvas]);
  useEffect(() => {
    if (canvas && settings) {
      const resources2 = canvas.nodes().filter((e) => e?.data("type") === "resource");
      const props = {
        accounts: "accountId",
        regions: "awsRegion",
        resourceTypes: "resourceType"
      };
      const relevantProps = Object.keys(props).filter(
        (prop) => settings[prop] && settings[prop].length > 0
      );
      const inSettingsArray = (r) => relevantProps.every((prop) => {
        return (settings[prop] ?? []).includes(
          r.data("properties")[props[prop]]
        );
      });
      const toRemove = settings?.hideSelected ?? true ? resources2.filter(
        (r) => r.data("properties") && inSettingsArray(r)
      ) : resources2.filter(
        (r) => r.data("properties") && !inSettingsArray(r)
      );
      if (toRemove.length > 0 && relevantProps.length > 0) {
        removeResource(
          canvas,
          updateCanvas,
          updateResources,
          toRemove.map((i) => i.id())
        );
      }
      canvas.edges().style(
        "visibility",
        settings?.hideEdges ?? false ? "hidden" : "visible"
      );
      if (!settings?.hideEdges && settings?.dataFlowEdgesOnly) {
        canvas.edges('edge[label != "HAS_DATA_FLOW"]').style("visibility", "hidden");
      }
    }
  }, [canvas, resources, settings, updateCanvas, updateResources]);
  useEffect(() => {
    if (data && canvas && !canvas.destroyed()) {
      if (data.settings) {
        setSettings({
          ...data.settings,
          // create fallback for older diagrams as default value used to be null
          costInterval: data.settings.costInterval ?? DEFAULT_COSTS_INTERVAL
        });
      } else {
        setSettings({
          costInterval: DEFAULT_COSTS_INTERVAL,
          accounts: [],
          regions: [],
          resourceTypes: [],
          hideSelected: true,
          hideEdges: false
        });
      }
      addResources(
        canvas,
        updateCanvas,
        updateResources,
        R.pick(["nodes", "edges"], data),
        getStandardLayout
      );
    }
  }, [canvas, data, updateCanvas, updateResources]);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(
    Breadcrumbs,
    {
      items: [
        { text: "Diagrams", href: DRAW },
        { text: name, href: OPEN_DIAGRAM }
      ]
    }
  ), /* @__PURE__ */ React.createElement(
    Container,
    {
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          variant: "h2",
          actions: /* @__PURE__ */ React.createElement(DiagramControlPanel, { settings })
        },
        name
      )
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ResourceSearch, null), /* @__PURE__ */ React.createElement(PureCytoscape, { name, visibility }), settings && /* @__PURE__ */ React.createElement(ExpandableSection, { header: "Diagram Settings" }, /* @__PURE__ */ React.createElement(
      DiagramSettings,
      {
        initialSettings: settings,
        onSettingsChange: setSettings
      }
    )))
  ));
};
export default OpenDiagramPage;
